import React, { useState, useEffect } from 'react'
import styled from "styled-components";
import Responsive from '../util/responsive'
import Text from './Text'

const Color = styled.div`
  background: #16161C;
  width: 100%;
  color: #CDCDCD;
`;
const Logo = styled.div`
  background: url(/97th-floor-logo.svg);
  width: 75px;
  height: 75px;
  opacity: 1;
  cursor: pointer;
  ${Responsive.tabletPlus`
  width: 50px;
  height: 50px;
  background-size: 50px 50px;
  `}
  ${Responsive.mobilePlus`
    width: 50px;
    height: 50px;
    background-size: 50px 50px;
  `}
`
const Link = styled.a`
  display: block;
  position: absolute;
  top: 40px;
  right: 70px;
  text-decoration: none;
  color: inherit;
  cursor: pointer;

  ${Responsive.mobilePlus`
    top: 16px;
    right: 30px;
  `}
`

const LinkLogo = styled.a`
  display: block;
  position: absolute;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
`

const LinkLogoH = styled.a`
  display: block;
  position: absolute;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
`

const Children = styled.div`
  opacity: ${({fade}) => fade ? '0' : '1'};
  transition: opacity 2.5s;
`

const View = (props) => {
  const [fade, setFade] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setFade(false)
    }, 1000)
  }, [])
  
  if (window.location.pathname === "/about") {
    return (
      <>
        <header id="header">
          <LinkLogo id="logo97" href="/">
            <Logo id="logo97" />
          </LinkLogo>
        </header>
        <Color>
          <Children fade={fade}>
            {props.children}
          </Children>
        </Color>
      </>
    )
  }
  return (
    <>
    <header id="header">
      <LinkLogoH id="logo97" rel="noopener" href="https://hubs.la/H0xFNq80">
        <Logo id="logo97"/>
      </LinkLogoH>
      <Link id="linkabout" href="/about">
        <Text subtitle>About</Text>
      </Link>
    </header>
    <Color>
      <Children fade={fade}>
        {props.children}
      </Children>
    </Color>
    </>
  )
}

export default View;
