import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import firebase from '../util/firebase'

import Flex from './Flex'
import Text from './Text'

const Options = styled.div`
  display: flex;
  border: 1px solid #707070;
  width: 300px;
  margin-top: 20px;
`

const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 23px;
  &:first-child {
    border-right: 1px solid #707070;
  }
  width: 100%;
  color: white;
  background: transparent;
  cursor: pointer;
  transition-duration: 0.3s;
  &:hover {
    background: #707070;
  }
`

const Result = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 23px;
  min-width: 55px;
  color: white;
  width: ${({width}) => width || 'inherit'};
  ${({majority}) => majority && css`
    background: #707070;
    p {
      color: white;
    }
  `}
`

const Percentage = styled.p`
  position: absolute;
  top: 100%;
  left: 0;
  text-align: center;
  width: 100%;
  padding: 10px 0;
  font-family: 'Khula', sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  color: #57585D;
  letter-spacing: 1px;
  font-size: 15px;
`

const View = ({question, pollKey , sectionTitle}) => {
  const [picked, setPicked] = useState(false)
  const [results, setResults] = useState({biden: 1, trump:1})

  useEffect(async () => {
    if(localStorage[pollKey] === 'chosen'){
      setPicked(true)
      const ref = await firebase.db.collection('polls').doc(pollKey).get()
      setResults(ref.data())
    }
  }, [])

  async function selectOption(option){
    console.log('select option')
    localStorage.setItem([pollKey], 'chosen')
    const result = await firebase.updatePoll({
      person: option,
      poll: pollKey
    })

    const ref = await firebase.db.collection('polls').doc(pollKey).get()
    setResults(ref.data())
    setPicked(true)
  }

  function getPercentage(person){
    return (results[person] / (results.biden + results.trump)) * 100
  }

  return (
    <div>
      <Flex column margin="3rem auto 0" align="center" height="">
        <Text subtitle>{sectionTitle}</Text>
        <Text subtitle>Poll</Text>
        <Text title margin="16px auto 0" mobilePlus={`margin: 8px auto 0;`} width="640px" center>
          {question}
        </Text>

        {picked ? (
          <Options>
            <Result majority={getPercentage('biden') > 50} width={`${ getPercentage('biden') }%`}>
              Biden
              <Percentage>{parseInt(getPercentage('biden'))} %</Percentage>
            </Result>

            <Result majority={getPercentage('trump') > 50} width={`${ getPercentage('trump') }%`}>
              Trump
              <Percentage>{parseInt(getPercentage('trump'))} %</Percentage>
            </Result>
          </Options>
        ) : (
          <Options>
            <Option onClick={() => selectOption('biden')}>
              Biden
            </Option>

            <Option onClick={() => selectOption('trump')}>
              Trump
            </Option>
          </Options>
        )}
        
      </Flex>
    </div>
  )
}

export default View