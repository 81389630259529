import React, { useEffect } from 'react'
import styled from 'styled-components'

import Responsive from '../util/responsive'
import { Background, Flex, Text } from '../components'

const Relative = styled.div`
  position: relative;
  overflow: hidden;
`
const Copy = styled.div`
  top: 40%;
  width: 100%;
  padding: 0 30px;
  margin-top: 60px;
  ${Responsive.mobilePlus`
    top: 25%;
  `}
`
const Spacer = styled.div`
  height: 22px;
`
const Link = styled.a`
  color: white;
  ${Responsive.mobilePlus`

  `}
`
const Logo = styled.div`
  background: url(/97th-floor-logo.svg);
  width: 75px;
  height: 75px;
  opacity: 1;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;

  ${Responsive.mobilePlus`
    width: 50px;
    height: 50px;
    background-size: 50px 50px;
  `}
`

const View = ({}) => {
  useEffect(() => {
    const script = document.createElement('script');
    const letsdiscuss = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    letsdiscuss.src = 'https://js.hscta.net/cta/current.js';
    document.body.appendChild(script);
    document.body.appendChild(letsdiscuss);

    script.addEventListener('load', () => {
    	if(window.hbspt) {
      	window.hbspt.forms.create({
        	portalId: '3416489',
          formId: 'ed870143-e804-4692-93d0-ee63621eb3e4',
          target: '#hubspotForm',
          cssClass: 'ppc'
        })
      }
    });

    letsdiscuss.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.cta.load(3416489, '8d18f86e-d90b-4e26-a29a-3b04c8039754', {})
      }
    });

  }, []);

  return (
    <>
    <Background>
      <Relative>
        <Copy>
          <Text title width="725px" margin="0 auto 60px auto" marginBottom="60px" center>
            About and Media Inquires
          </Text>
          <Text width="725px" margin="0 auto" size="17px">
            <Text subtitle margin="60px auto 20px" center>
              WHO DID ALL THIS ANYWAY?
            </Text>
            97th Floor is a full service, fully integrated, digital marketing agency with a mission: Make the internet a better place. With deep roots in organic, we’ve been building first-rate digital campaigns for over 15 years. 
            <br/>
            <br/>
            All the detail, intention, and zeal you see in this project is what we do for our clients every day. 
            <br/>
            <br/>
            <Flex mobilePlus={`flex-direction: column; align-items: flex-start`} justify-content="space-between">
              <p className="letsdiscussp">Want to find out what your next greatest campaign is? </p>
              <span className="hs-cta-wrapper" id="hs-cta-wrapper-8d18f86e-d90b-4e26-a29a-3b04c8039754">
                <span className="hs-cta-node hs-cta-8d18f86e-d90b-4e26-a29a-3b04c8039754" id="hs-cta-8d18f86e-d90b-4e26-a29a-3b04c8039754">
                  {/* <!--[if lte IE 8]>
                  <div id="hs-cta-ie-element"></div>
                  <![endif]--> */}
                  <a id="hubspotlink" href="https://cta-redirect.hubspot.com/cta/redirect/3416489/8d18f86e-d90b-4e26-a29a-3b04c8039754"  target="_blank" >
                    <img className="hs-cta-img" id="hs-cta-img-8d18f86e-d90b-4e26-a29a-3b04c8039754" style={{borderWidth: "0px"}} src="https://no-cache.hubspot.com/cta/default/3416489/8d18f86e-d90b-4e26-a29a-3b04c8039754.png"  alt="Let&#39;s discuss."/>
                  </a>
                </span>
              </span>
            </Flex>
            <br/>
            <br/>
            <Spacer/>
            <Text subtitle center max-width="80px" >
              I'M A POLITICAL AND/OR MARKETING JUNKIE, <br></br>
              CAN I SEE MORE OF THIS?
            </Text>
            <Spacer/>
            Absolutely! Give us your email, and we’ll send you the 192-page report with all the data and insights that didn’t make it into this website. If you’re with the press, visit our <Link target="_blank" rel="noopener" rel="noreferrer" href="https://www.prnewswire.com/news-releases/which-presidential-candidate-is-making-the-most-impact-with-their-marketing-campaign-301148589.html">press release</Link> and we’ll send along a few other points you might be interested in.
          </Text>
          <Copy>
            <div id="hubspotForm">
              <Text center subtitle color="white !important" >GET THE FULL REPORT</Text>
            </div>
          </Copy>
        </Copy>
      </Relative>
    </Background>
    </>

  )
}

export default View