import styled, { css } from "styled-components";
import responsive from '../util/responsive'

const Flex = styled.div`
  display: flex;
  width: 100%;
  max-width: ${({ width }) => width || "inherit"};
  min-width: ${({ minWidth }) => minWidth || "inherit"};
  align-items: ${({ align }) => align || "center"};
  justify-content: ${({ justify }) => justify || "flex-start"};
  margin: ${({ margin }) => margin || "0"};
  padding: ${({ padding }) => padding || "0"};
  flex-wrap: ${({ wrap }) => (wrap ? "wrap" : "nowrap")};

  ${({ column }) =>
    column &&
    css`
      flex-direction: column;
    `}
  
  ${ ({mobilePlus}) => mobilePlus && `${responsive.mobilePlus`${mobilePlus}`} `}
  ${ ({tabletPlus}) => tabletPlus && `${responsive.tabletPlus`${tabletPlus}`} `}

`;

export default Flex;
