import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Helmet from "react-helmet";

import Home from './pages/Home'
import About from './pages/About'

function App() {
  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />

        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/fullPage.js/3.0.9/fullpage.min.css"
        />

        <link href="https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@500&family=Khula:wght@400;600&family=Source+Sans+Pro:wght@300;700&display=swap" rel="stylesheet" />
      </Helmet>
    
      <Router>
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About} />
      </Router>
    </>
  );
}

export default App;
