import styled, { css } from "styled-components";
import responsive from '../util/responsive'

const Text = styled.p`
  font-size: ${({ size }) => size || "16px"};
  font-weight: ${({ weight }) => weight || "inherit"};
  color: ${({ color }) => color || "#CDCDCD"};
  max-width: ${({ width }) => width || "inherit"};
  margin: ${({ margin }) => margin || "0"};
  padding: ${({ padding }) => padding || "0"};
  text-align: ${({ center }) => (center ? "center" : "inherit")};
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  line-height: 1.8em;

  ${({ subtitle }) => subtitle && css`
    font-family: 'Khula', sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    color: #57585D;
    letter-spacing: 3px;
    font-size: 15px;
  `}

  ${({ title }) =>
    title &&
    css`
      font-size: 45px;
      font-style: normal;
      font-weight: 500;
      font-family: 'Cormorant Garamond', serif;
      line-height: 1em;
      ${responsive.tablet`
        max-width: 550px;
      `}
      ${responsive.mobilePlus`
        font-size: 24px;
      `}
  `}

  ${({ heroTitle }) =>
  heroTitle &&
  css`
    font-size: 45px;
    font-style: normal;
    font-weight: 500;
    font-family: 'Cormorant Garamond', serif;
    line-height: 1em;
    ${responsive.tabletPlus`
      font-size: 30px;
    `}
    ${responsive.tablet`
      max-width: 550px;
    `}
    ${responsive.mobilePlus`
      font-size: 24px;
    `}
`}

  ${({ mainTitle }) =>
    mainTitle &&
    css`
      font-size: 30px;
      font-style: bold;
      font-weight: 400;
      font-family: 'Cormorant Garamond', serif;
      line-height: 1em;
      ${responsive.tablet`
        max-width: 550px;
      `}
      ${responsive.mobilePlus`
        font-size: 24px;
      `}
  `}

  ${({ year }) =>
  year &&
  css`
    font-size: 38px;
    font-style: bold;
    font-weight: 400;
    font-family: 'Cormorant Garamond', serif;
    line-height: 1em;
    padding-bottom: 5px;
    padding-right: 8px;
    ${responsive.tablet`
      max-width: 550px;
    `}
    ${responsive.mobilePlus`
      font-size: 26px;
      padding-bottom: 2px;
    `}
`}

  ${({mobilePlus}) => mobilePlus && `${responsive.mobilePlus`${mobilePlus}`}`}
  ${({tablet}) => tablet && `${responsive.tablet`${tablet}`}`}

`;

export default Text;