import React from 'react'
import styled from 'styled-components'
import responsive from '../util/responsive'
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Image = styled.img`
  width: ${({width}) => width || 'auto'};
  max-width: ${({maxWidth}) => maxWidth || '100%'};
  height: ${({height}) => height || 'auto'};
  max-height: ${({maxHeight}) => maxHeight || '100%'};
  margin: ${({ margin }) => margin || "0"};
  padding: ${({ padding }) => padding || "0"};

  ${ ({mobilePlus}) => mobilePlus && `${responsive.mobilePlus`${mobilePlus}`} `}
  ${ ({mobile}) => mobile && `${responsive.mobile`${mobile}`} `}
  ${ ({desktop}) => desktop && `${responsive.desktop`${desktop}`} `}
  ${ ({tabletPlus}) => tabletPlus && `${responsive.tabletPlus`${tabletPlus}`} `}
  ${ ({tablet}) => tablet && `${responsive.tablet`${tablet}`} `}
`

export default Image