import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import responsive from '../util/responsive'

const Markdown = styled(ReactMarkdown)`
  max-width: 800px;
  margin: 0 auto;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 10rem;

  p {
    font-size: 16px;
    line-height: 1.8em;
  }

  strong {
    font-weight: 700;
    color: ${({blue}) => blue ? '#0E68DD' : '#B93E40'};
  }

  ${responsive.mobilePlus`
    p {
      font-size: 14px;
      line-height: 1.5em;
    }
  `}
`

export default Markdown