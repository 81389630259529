import { css } from "styled-components";

const size = {
  mobileMini: "342px",
  mobile: "400px",
  mobilePlus: "700px",
  tablet: "800px",
  tabletPlus: "1000px",
  tabletLandscape: "1024px",
  desktop: "1150px",
  desktopPlus: "1255px",
  desktopDouble: "1500px",
  desktopTriple: "1671px"
};

export default Object.keys(size).reduce((acc, label) => {
  acc[label] = (...args) => `
  @media (max-width: ${size[label]}) {
    ${css(...args)}
  }
`;
  return acc;
}, {});
