import React from 'react'
import styled from 'styled-components'

import Responsive from '../util/responsive'
import Flex from './Flex'
import Text from './Text'
import Image from './Image'

const Relative = styled.div`
  position: relative;
  height: 100%;
  padding: 5% 0;
  overflow: hidden;

  ${Responsive.mobilePlus`
    padding: 60px 0 0;
    height: 35rem;
  `}
`

const Copy = styled.div`
  top: 40%;
  width: 100%;
  padding: 0 30px;

  ${Responsive.mobilePlus`
    top: 25%;
    margin-top: 100px;
  `}
`

const View = () => {

  return (
    <Relative>
      <Flex width="1000px" margin="0 auto" justify="space-between" mobilePlus={'padding: 0 45px;'}>
        <Image maxWidth="100%" maxHeight="525px" mobilePlus={`transform:scale(1.8) !important`} mobile={`transform:scale(2.5) !important`} src="/kanye.png" alt="97f-pres-marketing-kanye" />
      </Flex>
      <Copy>
        <Text title width="725px" margin="-20px auto 22px !important;" mobilePlus={`margin-top: -20px !important`} center>You didn’t think we’d forget Kanye, did you?</Text>
        <Text width="725px" margin="auto" size="16px" mobilePlus={`font-size: 14px; line-height: 1.5; margin-top: 1rem`}>
          Rapper, producer, and designer Kanye West isn’t a serious contender for the 2020 Presidential ballot. But we can’t deny Yeezy’s eccentric personality bleeds into his marketing, providing a handful of wild merits and shortcoming worth sharing.
        </Text>
      </Copy>
    </Relative>
  )
}

export default View