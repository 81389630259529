import randomstring from "randomstring";
import * as firebase from "firebase/app";
import axios from 'axios'
require("firebase/firestore");
require("firebase/storage");
require("firebase/auth");
require("firebase/functions");

const app = firebase.initializeApp({
  apiKey: "AIzaSyDmmmdYqKkJC0TIszBm9-5pmqCSGP8kH4Y",
  authDomain: "presidential-marketing.firebaseapp.com",
  databaseURL: "https://presidential-marketing.firebaseio.com",
  projectId: "presidential-marketing",
  storageBucket: "presidential-marketing.appspot.com",
});

const db = app.firestore();
const storage = app.storage();
const functions = app.functions();

const updatePoll = (poll) => {
  return axios.get(`https://us-central1-presidential-marketing.cloudfunctions.net/updatePoll?poll=${poll.poll}&person=${poll.person}`)
};

const options = {
  updatePoll,
  db
};

export default options;
