import React from "react";
import styled from "styled-components";

import Responsive from "../util/responsive";
import Flex from "./Flex";
import Text from "./Text";
import Image from "./Image";

const Relative = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
`;
const Copy = styled.div`
  position: absolute;
  top: 40%;
  width: 100%;
  padding: 0 15px;

  ${Responsive.mobilePlus`
    top: 17%;
    margin-top: 100px;
  `}
  ${Responsive.mobile`
    top: 17%;
    margin-top: 25px;
  `}
`;

const View = () => {

  return (
    <>
      <Relative>
        <Flex
          width="1000px"
          margin="10px auto"
          justify="space-between"
          mobilePlus={"padding: 0 45px; margin: 50px 0px 0px;"}
        >
          <Image
            maxWidth="50%"
            maxHeight="525px"
            src="/hero-2.png"
            alt="97f-pres-marketing-hero-2"
          />

          <Image
            maxWidth="50%"
            maxHeight="525px"
            src="/hero-1.png"
            alt="97f-pres-marketing-hero-1"
          />
        </Flex>
        <Copy>
          <Flex justify="center">
            <Flex justify="center">
              
              <Text year>2020</Text>
              <Text mainTitle text-align="center !important">
                Election Marketing Report
              </Text>
            </Flex>
            
          </Flex>
          <Text
            heroTitle
            width="826px"
            margin="22px auto !important"
            center
            mobilePlus={"margin-bottom: 20px;"}
          >
            The revolutionary, the horrible, and the stupid from Biden and
            Trump’s marketing campaigns
          </Text>

          <Text
            width="725px"
            margin="0 auto"
            size="17px"
            mobilePlus={"font-size: 15px; line-height: 1.6rem"}
          >
            97th Floor, an integrated digital marketing agency, set out to get
            to the bottom of the Biden and Trump campaigns. 97th Floor pulled
            thousands of digital ads, reviewed millions of dollars in ad spend,
            poured over scores of landing pages, read hundreds of emails, and
            spent entire days looking at websites, mobile apps, and social media
            accounts — they even donated $100 to both campaigns to see emails
            and ad retargeting strategies first-hand.
            <br />
            <br />
            The two campaigns are equal parts predictable and riveting. It’s a
            story of massive oversights, well-timed reactions, millions of
            wasted dollars, clever platform plays, and good ole fashioned
            name-calling.
          </Text>
        </Copy>
      </Relative>
    </>
  );
};

export default View;
