import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import styled from 'styled-components'
import randomstring from 'randomstring'

import responsive from '../util/responsive'
import Slide from './Slide'
import Poll from './Poll'
import Kanye from './Kanye'

const Wrapper = styled.div`
  height: ${({height}) => height || '100%'};
  min-height: 100%;
  width: 100%;
  padding: 45px 0px;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  
  ${responsive.mobilePlus`
    padding: 30px 0px;
  `}
`

const SliderWrap = styled.div`
  width: 100%;
  max-width: 935px;
  margin: 0 auto;
`

const PrevArrowElement = styled.div`
  width: 22px;
  height: 20px;
  background: url(/arrow-left.svg);
  opacity: 1;
  position: absolute;
  top: 100px;
  left: 50px;
  z-index: 2;
  cursor: pointer;

  ${responsive.mobilePlus`
    left: 5px;
  `}
`

const NextArrowElement = styled.div`
  width: 22px;
  height: 20px;
  background: url(/arrow-right.svg);
  opacity: 1;
  position: absolute;
  top: 100px;
  right: 50px;
  z-index: 2;
  cursor: pointer;

  ${responsive.mobilePlus`
    right: 5px;
  `}
`

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <NextArrowElement
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <PrevArrowElement
      onClick={onClick}
    />
  );
}

const TopicSection = ({ slides, title }) => {
  const [width, setWidth] = useState()
  const [height, setHeight] = useState(false)

  useEffect(() => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)

    window.addEventListener('resize', () => {
      setHeight(window.innerHeight)
      setWidth(window.innerWidth)
    })
  }, [])

  const settings = {
    infinite: true,
    fade: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    accessibility: true,
    responsive: [
      {
        breakpoint: 700,
        settings: {
          centerMode: true,
          centerPadding: "6%",
          slidesToShow: 1,
          fade: false,
        }
      }
    ]
  }

  if(!width) return null
  return (
    <Wrapper height={height ? `${height}px` : null}>
      <SliderWrap>
        <Slider {...settings}>
          {slides && slides.map((slide, index) => {
            if (slide.kanye) return <Kanye key={randomstring.generate()}/>
            if (slide.poll) return (<Poll sectionTitle={title} key={slide.pollKey} {...slide} />)
            if (width < 700 && slide.comparison) return ([
                <Slide highres={(width > 700)} sectionTitle={title} key={randomstring.generate()} step={index + 1} mobileNum={0} total={slides.length} {...slide}/>,
                <Slide highres={(width > 700)} sectionTitle={title} key={randomstring.generate()} step={index + 1} mobileNum={1} total={slides.length} {...slide}/>
            ])
            return (<Slide highres={(width > 700)} sectionTitle={title} key={randomstring.generate()} step={index + 1} total={slides.length} {...slide}/>)
          })}
        </Slider>
      </SliderWrap>
    </Wrapper>
  )
}

export default TopicSection