import React, { useEffect, useState } from 'react'
import fullpage from 'fullpage.js'
import firebase from '../util/firebase'
import styled from 'styled-components'

import Responsive from '../util/responsive'
import { Background, TopicSection, Hero, Kanye, Text } from '../components'

const Topic = styled.div`
  position: fixed;
  top: 50%;
  left: 12.95rem !important;
  right: 88%;
  width: 200px;
  transform: translateY(-240px) rotate(-90deg);
  z-index: 2;
  font-family: 'Khula',sans-serif;
  overflow: hidden;
  white-space: nowrap;

  ${Responsive.desktopTriple`
    left: 7rem !important;
  `}

  ${Responsive.desktopDouble`
    left: 3rem !important;
  `}

  ${Responsive.desktopPlus`
    left: -1rem !important;
  `}
  
  ${Responsive.desktop`
    left: -3rem !important;
  `}

  ${Responsive.tabletLandscape`
    left: -4.58rem !important;
  `}

  ${Responsive.tabletPlus`
    left: -70px !important;
  `}

  ${Responsive.mobilePlus`
    display: none;
  `}
`

const View = () => {
  const [topic, setTopic] = useState('')
  const sections = ['', 'WEBSITE UX', 'GOOGLE & YOUTUBE ADS', 'EMAIL', 'SOCIAL MEDIA', 'FACEBOOK ADS', 'SEO', 'DESIGN', 'MOBILE APP UX', 'TV COMMERCIALS', 'KANYE']

  useEffect(() => {
    new fullpage('#fullpage', {
      autoScrolling: true,
      touchSensitivity: 15,
      navigation: true,
      navigationPosition: 'left',
      navigationTooltips: sections,
      onLeave: function(origin, destination, direction){
        setTopic(sections[destination.index])
      }
    })
  }, [])

  return (
    <Background>
      <Topic>
        <Text padding="2px 0 0" subtitle>{topic}</Text>
      </Topic>

      <div id="fullpage">
        <div className="section">
          <Hero />
        </div>

        <div className="section">
          <TopicSection
            title="WEBSITE UX"
            slides={[
              {
                comparison: true,
                title: 'Biden favors togetherness while Trump is for Trump',
                images: ["biden-ux-1.png", "trump-ux-1.png"],
                alt: ["biden-ux-1", "trump-ux-1"],
                copy: [
                  `Photos __emphasizing togetherness and diversity dominate the Biden campaign's website.__ Joe Biden is most often seen with others, rather than alone. this message is reinforced by his copy, as even his CTAs use the word "together".`,
                  `The Trump campaign leans instead into the fame of Trump himself. The __photos and messaging are very Trump-focused,__ and there is little information about where he stands on any one "issue." Also missing are mentions of his goals, values, and recognition of other leaders like VP Pence who are politically tied to Trump.`
                ]
              },
              {
                comparison: true,
                title: 'Trump makes it easy to get involved',
                images: ["biden-ux-2.png", "trump-ux-2.png"],
                alt: ["biden-ux-2", "trump-ux-2"],
                copy: [
                  `The Biden campaign’s website __doesn’t use clear messaging in conveying how to volunteer or get involved__. His header uses the phrase “take action,” which is a little vague, and the site has only one option when signing up to volunteer.`,
                  `On the other hand, the Trump site uses the clear messaging “get involved” in the header. The site also makes __volunteering more precise by giving various actions someone could take__ in order to show support for the campaign.`
                ]
              },
              {
                comparison: true,
                title: 'Biden presses the attack on Trump',
                images: ["biden-ux-3.png", "trump-ux-3.png"],
                alt: ["biden-ux-3", "trump-ux-3"],
                copy: [
                  `Defeating the opposition is a greater focus on the Biden site. __Trump is mentioned regularly by name,__ and the messaging creates a sense of urgency in uniting against him.`,
                  `The Trump campaign’s homepage __does not mention Biden at all.__ However, the site also does not reach out to demographics outside of Trump’s base — one possible exception being a few of the models he chose to feature on his page.`
                ]
              },
              {
                comparison: true,
                title: 'Trump isn’t sharing his plan, but Biden is',
                images: ["biden-ux-4.png", "trump-ux-4.png"],
                alt: ["biden-ux-4", "trump-ux-4"],
                copy: [
                  `There is a section within the Biden campaign’s website that __lays out his vision for the next four years and where he stands on particular issues.__ This clear messaging makes it easy to understand what he stands for. However, the random order and lack of visual stimulation to differentiate the boxes could leave the user overwhelmed and unsure where to begin.`,
                  `__It is harder to recognize and understand what the Trump campaign stands for, and what Trump’s vision is__ for the next four years. The focus is instead on past accomplishments, with a section called “Promises Kept.” However, this section takes the user to another website rather than a page within the main site, which makes for a less user-friendly experience.`
                ]
              },
              {
                comparison: true,
                title: 'Hats vs. masks',
                images: ["biden-ux-5.png", "trump-ux-5.png"],
                alt: ["biden-ux-5", "trump-ux-5"],
                copy: [
                  `Selling merchandise does not appear to be the main purpose for the Biden website, as the site has many calls to action on the home page. Notably different from the Trump campaign, team __Biden is selling “Biden/Harris” face masks.__`,
                  `__Aside from “contribute,” the largest call to action on the Trump site is “shop now.”__ After arriving at the shopping portal for the Trump campaign, it’s easy to see the emphasis on the characteristic “Make America Great Again” hats.`
                ]
              },
              {
                comparison: true,
                title: '404 pages perfectly executed for each audience',
                images: ["biden-ux-6.png", "trump-ux-6.png"],
                alt: ["biden-ux-6", "trump-ux-6"],
                copy: [
                  `The execution of 404 error pages on the Biden site shows a keen knowledge of their audience. __They use a play on words consistent with their values and messaging:__ “We couldn’t mask this error, but you should mask yourself.”`,
                  `The Trump site also shows that they understand their target audience with its error page. When users reach a 404 page the site displays an __unflattering photo in which Biden__ looks confused saying: “It appears you are as lost as me.”`
                ]
              },
              {
                comparison: true,
                title: 'Biden’s website is busy, Trump’s steadfast',
                images: ["biden-ux-7.png", "trump-ux-7.png"],
                alt: ["biden-ux-7", "trump-ux-7"],
                copy: [
                  `The Biden website gives the users plenty of options to donate, learn more, and volunteer. This strategy can __serve as a catch-all for engaged users,__ however these varying CTAs lack structural hierarchy and are likely to lose clicks.`,
                  `The website put together by the Trump campaign has a well structured set of calls to action, pushing users to get involved by shopping or donating. The downside of this approach is that the website __only caters to existing Trump supporters,__ without providing easy ways for undecided voters to learn more.`
                ]
              },
              {
                comparison: false,
                title: '"Battle for the Soul of the Nation" is the new "Make America Great Again"',
                images: ["biden-ux-8.png"],
                alt: ["biden-ux-8", "trump-ux-8"],
                copy: [
                  `Four years ago, voters knew the Trump campaign’s slogan, “Make America Great Again.” While there’s still lingering momentum there, and iterations like, “Keep America Great,” the Biden campaign’s emphasis on “Battle for the Soul of the Nation” could become the clearer campaign slogan this round.`
                ]
              },
              {
                poll: true,
                question: "In your opinion, who's website has better website UX?",
                pollKey: 'website-ux'
              }
            ]}
          />
        </div>

        <div className="section">
          <TopicSection 
            title="GOOGLE & YOUTUBE ADS"
            slides={[
              {
                comparison: true,
                title: 'Trump consistently outspends Biden',
                images: ["biden-google-1.png", "trump-google-1.png"],
                alt: ["biden-google-1", "trump-google-1"],
                copy: [
                  `Google Ad spend for the Biden campaign was $5.9M in June, $3.9M in July, and $17.9M in August. That’s a total of $27.8M on Google Ads in the last three months, __$14M less than Trump’s campaign during the same time.__`,
                  `Trump’s campaign seems to have more money to spend than the Biden campaign. They consistently __outspend Biden’s campaign on Google Ads month to month.__ The Trump campaign spent $6.4M in June, $10.5M in July, and $25.1M in August, totalling at $42.1M over the past three months.`
                ]
              },
              {
                comparison: true,
                title: 'Biden’s image ads favor accessibility, while Trump’s are anti-Biden',
                images: ["biden-google-2.png", "trump-google-2.png"],
                alt: ["biden-google-2", "trump-google-2"],
                copy: [
                  `The image ads coming from Biden’s campaign are __inviting and accessible by a wide audience.__ Some ads are in Spanish, thus intentionally including an entire segment of the population that is sometimes excluded linguistically.`,
                  `The Trump campaign has either not run Spanish image ads at all, or very few are visible. Instead, the Trump campaign’s top image ads (high spend & high impressions) had __calls to action focused on Joe Biden__ such as: “Stop Joe & Kamala,” “Help Stop Joe,” and “Stand Against Joe Biden.”`
                ]
              },
              {
                comparison: false,
                title: 'Swing states lead in dollars spent, with blue then red states following',
                images: ["google-3.png"],
                alt: ["google-3"],
                copy: [
                  `Both campaigns are focusing their Google Ads budget on swing states. These states are receiving 40.33% of all Google Ads dollars, with blue states receiving 32.08% and red states receiving 27.58%. Swing states account for 30.11% of electoral votes, a smaller percentage than either blue or red states, but a handful of swing states can determine the outcome of the election.`
                ]
              },
              {
                comparison: false,
                title: 'Video ads lead out over Google’s other ad delivery platforms',
                images: ["google-4.png"],
                alt: ["google-4"],
                copy: [
                  `Both campaigns are pursuing video ads more than Google’s other ad delivery platforms (text and image). This strategy may be based on the fact that video is a more immersive experience for the viewer than text or image ads. It’s unusual however to see so little budget devoted to image ads, considering the breadth and sophistication of the Google Display Network.`
                ]
              },
              {
                comparison: false,
                title: 'Text ads don’t deviate from typical best practices',
                images: ["google-5.png"],
                alt: ["google-5"],
                copy: [
                  `The campaigns’ text-based ads use copy that is basic and largely static. If either campaign were a business, their search ads on Google would fit the mold of “branded bidding strategy.” They frequently use “Official Campaign Website” in their headlines. The Biden campaign frequently uses “Join the team,” “Join us,” or “Donate,” while the Trump campaign most frequently uses “Donate” or “Contribute.”`
                ]
              },
              {
                comparison: true,
                title: 'Trump accidentally paid for the keyword “how to impeach trump”',
                images: ["biden-google-6.png", "trump-google-6.png"],
                alt: ["biden-google-6", "trump-google-6"],
                copy: [
                  `Both campaigns (Trump more extensively than Biden) are paying for keywords they are accidently bidding on, likely through a broad modifier bidding strategy. While this can be a problem for all large accounts, __these campaigns are wasting money by not regularly adding negative keywords.__`,
                  `The Trump campaign in particular is __wasting a lot of money by neglecting to regularly comb through their Google Search Terms report__ and add new keywords to their negative keywords list. For instance, Trump’s campaign has paid money to serve on “speedo swim trunks,” “mens xxl swim shorts,” “trump is a disaster,” “how to impeach trump,” and “trump fraud.”`
                ]
              },
              {
                comparison: false,
                title: 'Florida is getting the most digital ad dollars',
                images: ["google-7.png"],
                alt: ["google-7"],
                copy: [
                  `Florida alone accounts for $32.7M in Google Ads spend and 8.51% of ad budget between the two candidates — the largest percentage of any state. Florida’s electoral college vote is 29, which is the largest of any swing state and thus a focus for both campaigns.`
                ]
              },
              {
                comparison: true,
                title: 'Biden’s search strategy prioritizes issues, Trump’s merchandise',
                images: ["biden-google-8.png", "trump-google-8.png"],
                alt: ["biden-google-8", "trump-google-8"],
                copy: [
                  `Search strategy between the two campaigns is widely different. The Biden campaign __prioritizes social issues in their bidding strategy__ such as “pro choice,” “stop gun violence,” and “redistricting.” Interestingly, Biden’s campaign has few “Biden” associated keywords.`,
                  `Alternatively, the Trump campaign focuses its __keyword bidding strategy on Trump merchandise__ along with variations on his name. Examples of these search terms include: “maga hat,” “trump hats,” “trump shirts,” “trump bumper sticker.”`
                ]
              },
              {
                comparison: true,
                title: 'Team Trump values video thumbnails',
                images: ["biden-google-9.png", "trump-google-9.png"],
                alt: ["biden-google-9", "trump-google-9"],
                copy: [
                  `The Biden campaign seems to have __forgotten to create video thumbnails optimized for earning a click.__ Many of Joe Biden’s top videos are simply a still frame accompanying the audio of a speech (occasionally paired with a random piece of closed captioning).`,
                  `The Trump campaign __clearly understands what an optimized thumbnail does for click-through-rates.__ The thumbnails featuring Trump included a text overlay and a patriotic setting. If the thumbnails featured Joe Biden, they had a gray-toned filter or an unflattering picture accompanied with negative text overlay.`
                ]
              },
              {
                poll: true,
                question: "In your opinion, who runs a better Google Ads campaign?",
                pollKey: 'google-ads'
              }
            ]}
          />
        </div>

        <div className="section">
          <TopicSection 
            title="EMAIL"
            slides={[
              {
                comparison: true,
                title: 'Biden’s subject lines are polished, Trump’s are flashy',
                images: ["biden-email-1.png", "trump-email-1.png"],
                alt: ["biden-email-1", "trump-email-1"],
                copy: [
                  `Professionalism seems to be a focus for the Biden campaign’s email copy. The subject lines don’t use all caps or emojis, and the punctuation is consistent and accurate. Their approach is also more personal, with nearly __one third of the subject lines addressing the subject by name.__`,
                  `The Trump campaign’s emails are not as personal, directly addressing their subjects only 10% of the time. They also tend to use emojis or garish subject lines to entice clicks and grab attention. Employing __tactics like all caps, countdowns, and deadlines,__ all to the same purpose. In short, the campaign’s email copy is action-driven.`
                ]
              },
              {
                comparison: false,
                title: 'Trump email campaign neglects non-donors',
                images: ["email-2.png"],
                alt: ["email-2"],
                copy: [
                  `Email addresses entered at the bottom of the Trump campaign’s home page will not even receive a welcome email if they do not donate. This has been the case with numerous emails entered over a wide time frame from a variety of locations — a massive oversight. This slip up only holds true for their website. The campaign sends emails to addresses earned through other means, like Facebook Ads, whether or not the individual donates.`
                ]
              },
              {
                comparison: true,
                title: 'Biden sends significantly fewer emails than Trump',
                images: ["biden-email-3.png", "trump-email-3.png"],
                alt: ["biden-email-3", "trump-email-3"],
                copy: [
                  `The Biden campaign seems to be using the “less is more” strategy on email frequency. Those that subscribe to the Biden campaign’s updates will generally __receive an email every other day,__ only occasionally receiving an email two days in a row.`,
                  `This strategy stands in contrast to the Trump campaign, which seems to believe “more is more.” The campaign __sends an average of 2.7 emails a day,__ more than three times that of the Biden campaign. On the most frequent days, donors receive as many as seven or eight emails in a single day.`
                ]
              },
              {
                comparison: false,
                title: 'Trump used “sleepy Joe” in 16 emails in 14 days',
                images: ["email-4.png"],
                alt: ["email-4"],
                copy: [
                  `Consistent with their other channels, the Trump campaign’s email content is divisive. In a recent 14 day period, the Trump campaign used "sleepy Joe" in its email content 16 times. The emails also include put-downs targeting Kamala Harris and Nancy Pelosi. The Biden campaign's emails on the other hand rarely mention Trump aside from contextual messaging or copy such as "...defeat Donald Trump this year!"`
                ]
              },
              {
                comparison: true,
                title: 'Biden discusses issues, Trump wants donations',
                images: ["biden-email-5.png", "trump-email-5.png"],
                alt: ["biden-email-5", "trump-email-5"],
                copy: [
                  `Biden: The issues central to the Biden campaign, as well as a focus on the email receiver, seem to be the highest priorities in the Biden email funnels. __Donation requests are slightly less prominent.__ However, the emails from this campaign are typically longer than most people want to read, which could cause lost interest and decreased email opens and clicks over time.`,
                  `Even those who have donated to the Trump campaign are daily asked to donate again. Most of this campaign’s emails also __offer some kind of match-donation incentive.__ The most common is a 7X match, where if the user donates $5, the Trump campaign will match with $35, making the user feel like their donation is actually $40.`
                ]
              },
              {
                comparison: true,
                title: 'The Trump surname vs. Biden’s diversity',
                images: ["biden-email-6.png", "trump-email-6.png"],
                alt: ["biden-email-6", "trump-email-6"],
                copy: [
                  `The Biden campaign is leaning into a diverse set of senders, with __only about 30% of emails coming from Joe Biden__ himself. The majority instead stem from other personalities like Hilary Clinton, Yo-Yo Ma, and Kamala Harris.`,
                  `The Trump family name leads out email sends from the Trump campaign. Nearly half of the __emails come either from Donald Trump or from his close family__ members like Eric Trump, Lara Trump, Melania Trump, and Donald Trump Jr.`
                ]
              },
              {
                comparison: false,
                title: 'Donators get more emails in the Biden funnel',
                images: ["email-7.png"],
                alt: ["email-7"],
                copy: [
                  `It’s clear that the Biden campaign is sending different emails to those who donate, over those that haven’t donated. Those who have donated can expect about twice as many emails each week as those who haven’t. The non-donor emails contain messages like: “I’ve asked for your support before…” This is a sound email marketing strategy, as it makes sense to email financial supporters more often than non-donors.`
                ]
              },
              {
                comparison: false,
                title: 'Trump’s bait and switch emails',
                images: ["email-8.png"],
                alt: ["email-8"],
                copy: [
                  `Bait and switch tactics are as old as marketing itself, and the Trump campaign still uses it. Almost weekly, the Trump campaign sends an email to its list with an enticing headline such as: “Supreme Court Choice Attached,” or “Convention Speech Attached,” yet does not deliver the implied content. In cases like these, the body of the email did not explain who Trump’s Supreme court choice would be, and there was no attachment or mention of a convention speech. Instead, both emails pushed straight to asking for donations.`
                ]
              },
              {
                poll: true,
                question: "In your opinion, who runs a better email campaign?",
                pollKey: 'email'
              }
            ]}
          />
        </div>

        <div className="section">
          <TopicSection 
            title="SOCIAL MEDIA"
            slides={[
              {
                comparison: true,
                title: 'Trump leans on Facebook Shop, Biden on Facebook Groups',
                images: ["biden-social-media-1.png", "trump-social-media-1.png"],
                alt: ["biden-social-media-1", "trump-social-media-1"],
                copy: [
                  `Niche Facebook communities can be a __place for users to interact with each other and foster a good relationship with a brand.__ The Biden campaign uses Facebook Groups to create these communities for each state. Within these state groups fellow Biden supporters can feel part of a local, like-minded community.`,
                  `Facebook Shop allows a brand to feature a few products from their shop prominently. The Trump campaign __leans into these ecommerce functions over other profile features.__ Users are able to view products with easy links to purchase on the product website. This ease of use likely brings in more merchandise sales.`
                ]
              },
              {
                comparison: true,
                title: 'Both candidates have pinned posts of Biden on Twitter',
                images: ["biden-social-media-2.png", "trump-social-media-2.png"],
                alt: ["biden-social-media-2", "trump-social-media-2"],
                copy: [
                  `It’s good practice to place your brand’s messaging prominently on your Twitter page by using the pinned tweet feature. The Biden campaign’s __pinned tweet functions as a mission statement with a call to action.__ It includes links to the candidate and his running mate’s personal Twitter accounts.`,
                  `Contrast this with the Trump campaign’s pinned tweet which is __a video of Joe Biden.__ Common use of #walkaway and #WalkAwayFromDemocrats suggests that his followers engage with this type of content.`
                ]
              },
              {
                comparison: false,
                title: 'Social media is a digital echo chamber',
                images: ["social-media-3.png"],
                alt: ["social-media-3"],
                copy: [
                  `There’s only 1.3% of crossover on the accounts that Trump and Biden loyalists follow. This means that when someone shares their opinion online, they are effectively tweeting into an echo chamber that likely validates their own views and biases, rather than an evenly divided audience.`
                ]
              },
              {
                comparison: true,
                title: 'Biden and Trump audiences describe themselves in somewhat predictable ways',
                images: ["biden-social-media-4.png", "trump-social-media-4.png"],
                alt: ["biden-social-media-4", "trump-social-media-4"],
                copy: [
                  `The words that followers of these accounts most often __use to describe themselves were unsurprising.__ The Biden campaign’s followers tend to use words like “she/her,” “feminist,” “activist,” and “liberal.”`,
                  `Conversely, followers of the Trump campaign __use words more typical of that campaign’s following__ including: “KAG,” “patriot,” “conservative,” and “retired.”`
                ]
              },
              {
                comparison: false,
                title: 'Trump supporters often talk about Democrats',
                images: ["social-media-5.png"],
                alt: ["social-media-5"],
                copy: [
                  `An analysis of how frequent visitors of the Biden and Trump sites speak on social media, found that “democrat” was the most common word visitors to the Trump site use in their tweets. However, “republican” is 8th on the list for Biden site visitors. This means that Trump site visitors are twice as likely to talk about the opposing party on social media when compared to Biden site visitors.`
                ]
              },
              {
                comparison: false,
                title: 'Instagram is still sacred',
                images: ["social-media-6.png"],
                alt: ["social-media-6"],
                copy: [
                  `Neither campaign asks for donations over Instagram, possibly because the app doesn’t allow for links in post descriptions. This could be overcome, however, by pointing users to a link in the account description box. Branded hashtags are another best practice that both accounts undervalue. The Trump campaign only uses hashtag clouds when posting about an event. The Biden campaign never makes use of more than one or two hashtags in a single post, and generally these hashtags relate to a movement or event such as #Juneteenth, #SOULSaturday, or #EarthDay.`
                ]
              },
              {
                comparison: true,
                title: 'Biden runs a stronger Instagram account',
                images: ["biden-social-media-7.png", "trump-social-media-7.png"],
                alt: ["biden-social-media-7", "trump-social-media-7"],
                copy: [
                  `The Biden campaign’s __Instagram is well built.__ They regularly post stories, and their team has done a good job optimizing all of the campaign images to feel similar and cohesive. This is a good move for the campaign, as their primary audience tends to be active on Instagram.`,
                  `Twitter meets the needs of the Trump campaign better than Instagram, given their core audience. Consequently, the campaign’s Instagram account is not as built out. The __images are poorly formatted and lack cohesion,__ and the account’s bio does not give much information about their mission.`
                ]
              },
              {
                comparison: false,
                title: 'Biden’s social strategy makes strategic jabs',
                images: ["social-media-8.gif"],
                alt: ["social-media-8"],
                copy: [
                  `Donald Trump runs a very active Twitter account. However, the Joe Biden campaign has traditionally been less aggressive with social content. That is, until this ad showed up. It’s subtle enough to preserve the campaign’s peaceful branding, but stinging enough to make a point. The ad also follows best social media practices by including a short video complete with subtitles.`
                ]
              },
              {
                comparison: false,
                title: 'Biden has a podcast',
                images: ["social-media-9.png"],
                alt: ["social-media-9"],
                copy: [
                  `The Biden campaign has branched out and explored other ways to reach their audience, including podcasting. This is generally a good tactic, although in this case the data suggests that their followers are more actively engaging with the campaign through other channels.`
                ]
              },
              {
                poll: true,
                question: "In your opinion, who runs a better social media campaign?",
                pollKey: 'social-media'
              }
            ]}
          />
        </div>

        <div className="section">
          <TopicSection 
            title="FACEBOOK ADS"
            slides={[
              {
                comparison: true,
                title: 'Trump’s best ad lands well in Texas, Biden’s California',
                images: ["biden-facebook-1.png", "trump-facebook-1.png"],
                alt: ["biden-facebook-1", "trump-facebook-1"],
                copy: [
                  `The Biden campaign’s best performing ad was targeted at a majority of people who were between 35-65 (most heavily 55-65) and female. __The most highly targeted states were California (at 17%), New York (at 7%), and Texas/Florida (at 6%).__`,
                  `The Trump campaign’s best performing ad targeted middle-aged people (most heavily 25-54), evenly men and women. __Their most highly targeted states were Texas (at 10%), California (at 7%), and Florida (at 6%).__`
                ]
              },
              {
                comparison: true,
                title: 'Gentle vs. divisive ad content',
                images: ["biden-facebook-2.png", "trump-facebook-2.png"],
                alt: ["biden-facebook-2", "trump-facebook-2"],
                copy: [
                  `__The top five ads from the Biden campaign consistently ask for a simple $10 donation,__ usually to meet a time-specific goal. Generally, Biden himself is the chosen image, and the buttons on his images make for stronger calls to action.`,
                  `The Trump campaign focuses on disparaging Democrats in general. The __ads ask users to take one-sided surveys__ (like the “Official Democrat Corruption Accountability Survey”) with donation requests included, rather than asking for donations directly.`
                ]
              },
              {
                comparison: true,
                title: 'Trump spends more, but Biden moves fast',
                images: ["biden-facebook-3.png", "trump-facebook-3.png"],
                alt: ["biden-facebook-3", "trump-facebook-3"],
                copy: [
                  `Historically, the Biden campaign has spent less than the Trump campaign __(an average of 1.1M less per month).__ However, the past three months the Biden campaign has started spending more within Facebook — 9.7M in June, 3.4M in July, and 21.5M in August.`,
                  `Before the past three months, the __Trump campaign spent an average of 2.2M per month,__ which is nearly double the Biden campaign. The margin has been smaller recently, with the Trump campaign spending 6.5M in June, 11.7M in July, and 21.5M in August`
                ]
              },
              {
                comparison: false,
                title: 'Florida is getting the most attention from both sides',
                images: ["facebook-4.png"],
                alt: ["facebook-4"],
                copy: [
                  `More Facebook ad dollars are going into Florida than any other state. The Trump campaign is spending $4,419,089, or 10.56% of their total budget. The Biden campaign is spending $4,278,966, or 12.79% of their budget.`
                ]
              },
              {
                comparison: false,
                title: 'Biden is spending more in 3 of the top 5 most contested states',
                images: ["facebook-5.png"],
                alt: ["facebook-5"],
                copy: [
                  `The top five states receiving the most ad dollars cumulatively are: Florida ($8,698,055.00), California ($6,513,423.00), Texas ($5,392,034.00), Pennsylvania ($4,863,171.00), and New York ($3,623,264.00). Currently, the Trump campaign is spending more in Florida and Texas, while the Biden campaign is outspending in California, Pennsylvania, and New York.`
                ]
              },
              {
                comparison: true,
                title: 'Trump creates more ad variants than Biden',
                images: ["biden-facebook-6.png", "trump-facebook-6.png"],
                alt: ["biden-facebook-6", "trump-facebook-6"],
                copy: [
                  `While the Biden campaign has fewer ads, that’s not necessarily a bad thing. It does mean that the __Biden campaign is likely getting a higher frequency on their ads__ (the same people see them more often). This matters in a political race where the timeline is so short — voter’s vigor might increase with higher ad frequency.`,
                  `The Trump campaign makes use of more ads than the Biden campaign, and, additionally, many of them are localized. This means that they are probably __seeing higher engagement numbers from targeted local markets.__ All total, the Trump campaign has run 37,502 different ads, compared to Biden’s 27,207.`
                ]
              },
              {
                comparison: false,
                title: 'Biden prioritizes swing states, but Trump spends more',
                images: ["facebook-7.png"],
                alt: ["facebook-7"],
                copy: [
                  `The Biden campaign almost always allocates more of their budget (percentage-wise) to the swing states than the Trump campaign, but the Trump campaign has more overall budget to push, so they nearly always out spend the Biden campaign on the swing state battlegrounds. However, the Biden campaign might just be planning to spring forward later on, and so holding back on budget for now.`
                ]
              },
              {
                comparison: true,
                title: 'Trump seeks quiz engagement over direct donations, but there’s a catch',
                images: ["biden-facebook-8.png", "trump-facebook-8.png"],
                alt: ["biden-facebook-8", "trump-facebook-8"],
                copy: [
                  `The language in the Biden campaign’s ads tends to be straightforward. The ads usually __ask for a small donation (such as $10), and use phrases like “chip in”__ quite often to appeal to a larger amount of smaller donors.`,
                  `While the ads used by the __Trump campaign typically ask for quiz or survey engagement (often to create distrust toward the Democratic party),__ rather than direct donations, the surveys always ask for donations at some point. So, while the intent is not as blatant, donations remain a high priority.`
                ]
              },
              {
                comparison: true,
                title: 'Biden pushes all Facebook Ads on Instagram as well',
                images: ["biden-facebook-9.png", "trump-facebook-9.png"],
                alt: ["biden-facebook-9", "trump-facebook-9"],
                copy: [
                  `It’s a recommended best practice that advertisers push the majority of their Facebook ads to Instagram as well. The Biden campaign seems to have taken this advice, __launching almost every ad to both Facebook and Instagram.__`,
                  `The Trump campaign, on the other hand, has decided to only __utilize Instagram placements 36% of the time.__ Perhaps this is a strategic move, as the Biden campaign caters to a younger audience (more frequently found on Instagram) and Trump tends to focus his efforts on older demographics.`
                ]
              },
              {
                poll: true,
                question: "In your opinion, who runs a better Facebook Ad Campaign?",
                pollKey: 'facebook-ads'
              }
            ]}
          />
        </div>

        <div className="section">
          <TopicSection 
            title="SEO"
            slides={[
              {
                comparison: true,
                title: 'Trump leads in gross organic searches',
                images: ["biden-seo-1.png", "trump-seo-1.png"],
                alt: ["biden-seo-1", "trump-seo-1"],
                copy: [
                  `The Biden campaign’s website ranks for __more organic keywords (91.2K vs 78.1K) and maintains a slight lead on total backlinks__ (2.1M vs 2.04M).`,
                  `The Trump campaign’s website has a __stronger presence in organic traffic (estimated 1.7M vs 498K per month).__ Part of this may be due to their four-year head start, as well as the fact that “trump” has a larger keyword search volume.`
                ]
              },
              {
                comparison: true,
                title: 'Trump has 10X search volume on Biden',
                images: ["biden-seo-2.png", "trump-seo-2.png"],
                alt: ["biden-seo-2", "trump-seo-2"],
                copy: [
                  `The keyword “Joe Biden” has a monthly search volume of 1,730,000. While that is much less than the Trump campaign’s largest keyword, the Biden campaign __ranks for significantly more keywords (91.2k compared to 78.1k)__ than the Trump campaign.`,
                  `“Trump” as a keyword has a __monthly search volume of 17,190,000— ten times the search volume__ of Biden’s largest keyword. And that’s not the Trump campaign’s only big-hitter keyword either, “Donald trump” has a MSV of 5,060,000.`
                ]
              },
              {
                comparison: true,
                title: 'Trump has more .gov domains linking to him',
                images: ["biden-seo-3.png", "trump-seo-3.png"],
                alt: ["biden-seo-3", "trump-seo-3"],
                copy: [
                  `Backlinks are critical for SEO, and linking from government entities (sites with .gov TLDs) can be extremely valuable. The __Biden campaign could use more .gov backlinks,__ as they currently have 19 links coming from just 6 .gov domains.`,
                  `When you look at the Trump campaign, they’ve earned or built 142 links coming from 15 .gov domains, __including 57 from ca.gov alone.__ While this is greater than the Biden campaign, both candidates should be able to earn substantially more .gov sites with even a small effort.`
                ]
              },
              {
                comparison: true,
                title: 'Biden leaves more backlinks on the table than Trump',
                images: ["biden-seo-4.png", "trump-seo-4.png"],
                alt: ["biden-seo-4", "trump-seo-4"],
                copy: [
                  `Biden: The Biden team acquired KamalaHarris.org when she joined the Biden ballot, but apparently they opted for a site-wide 302 redirect to the Biden site. While users will not know the difference, all of the SEO authority the Harris team spent building (including __42,687 backlinks) evaporates with this “temporary” redirect.__ This might be the biggest SEO mistake team Biden is making, and it’s an easy fix — switching the redirect from a 302 to a 301.`,
                  `Both sides have backlinks directing to dead pages on their site, another faux pa since this again leaves perfectly good link authority on the table. __Trump has 4,728 backlinks going to dead pages while Biden has 9,104.__ Both candidates could fix this by doing some strategic 301 redirects from these dead pages.`
                ]
              },
              {
                comparison: true,
                title: 'Trump passes Core Web Vitals assessment',
                images: ["biden-seo-5.png", "trump-seo-5.png"],
                alt: ["biden-seo-5", "trump-seo-5"],
                copy: [
                  `The Core Web Vitals assessment from Google is a pass or fail evaluation based on user-focused site speed metrics. The __mobile version of the Biden site does not pass the assessment,__ which could hurt the site when Google launches their 2021 Page Experience update. Although, this may not matter by 2021, as Biden may not wish to utilize the site at that point.`,
                  `__The Trump homepage passes this assessment on both mobile and desktop versions.__ While both candidates need to improve their mobile speed performance, the Trump website fares slightly better with a mobile speed score of 43/100 compared to Biden’s 24/100.`
                ]
              },
              {
                comparison: false,
                title: 'SEO is not a priority for either candidate, but Biden is slightly better off',
                images: ["seo-6.png"],
                alt: ["seo-6"],
                copy: [
                  `As SEO strategies often need months of execution to see calculable results, the speed at which a presidential race runs is likely too fast for either of these candidates to make a noticeable impact through search. It’s plain to see when looking at the top pages ranking on their sites that neither site is set up to rake in non-branded traffic, although the Biden campaign seems to have the bare bones makings of a non-branded SEO strategy with pages ranking for terms like “gun safety” and “immigration.”`
                ]
              },
              {
                comparison: false,
                title: 'Trump’s homepage is making basic SEO mistakes',
                images: ["seo-7.png"],
                alt: ["seo-7"],
                copy: [
                  `The homepage should be among the cleanest pages on a site. The Biden and Trump homepages are both making basic SEO errors, but Trump’s are more severe. The Biden homepage has multiple H1 tags, and has neglected to write alt-text descriptions for its images, while The Trump site is missing an H1 tag and leaves an H2 tag blank. Additionally, the Trump site doesn’t have a canonical link (opening the site to content duplication threats), and incorporates poor meta descriptions (which could hurt clickability).`
                ]
              },
              {
                comparison: true,
                title: 'Trump has custom subdomains for different demographics',
                images: ["biden-seo-8.png", "trump-seo-8.png"],
                alt: ["biden-seo-8", "trump-seo-8"],
                copy: [
                  `The Biden team has taken the approach of focusing only on his main site, and not creating separate subdomains. This has the benefit of consolidating all ranking authority and leaving no chance for subdomains to cannibalize each other. __A good choice for SEO, but the benefit of targeting specific audiences through subdomains is lost.__`,
                  `The Trump team built separate subdomains for groups of key demographics. Examples of this include blackvoices.donaldjtrump.com, latinos.donaldjtrump.com, and women.donaldjtrump.com. This gives Trump the flexibility to totally hone the message for each demographic, but that __flexibility comes at a cost because any backlink authority captured by the subdomains will not flow to the main site.__`
                ]
              },
              {
                poll: true,
                question: "In your opinion, who has better SEO?",
                pollKey: 'seo'
              }
            ]}
          />
        </div>

        <div className="section">
          <TopicSection 
            title="DESIGN"
            slides={[
              {
                comparison: true,
                title: 'Biden leans human while Trump pushes power',
                images: ["biden-design-1.png", "trump-design-1.png"],
                alt: ["biden-design-1", "trump-design-1"],
                copy: [
                  `Biden: Most of the Biden site’s screen displays a casual lifestyle video of Biden and Harris — __a human element to connect with.__ This casual friendliness also shows in the copy “chip in to elect Joe.” The site also makes good use of space with two calls to action above the fold.`,
                  `The Trump campaign’s website makes use of __full-screen design, maximizing on-page real estate__ and making for an easy mobile layout translation. The site begins with imagery of power, mastery, and importance and its copy follows suit: “Together, we are rebuilding our nation.”`
                ]
              },
              {
                comparison: true,
                title: 'Trump’s colors hold to foundation, while Biden’s push youth',
                images: ["biden-design-2.png", "trump-design-2.png"],
                alt: ["biden-design-2", "trump-design-2"],
                copy: [
                  `Without any work for the eye, color is arguably the most recognizable visual element a logo can have. The Biden campaign selected a color palette of bright navy and candy apple red. The tones feel __youthful and lively, exuding spirited energy.__`,
                  `On the other hand, the Trump campaign’s color palette is a dark, rich navy and a deep, crimson red. The palette’s tones are __serious, firm, and hint at an established foundation.__`
                ]
              },
              {
                comparison: true,
                title: 'Logo readability goes to Biden',
                images: ["biden-design-3.png", "trump-design-3.png"],
                alt: ["biden-design-3", "trump-design-3"],
                copy: [
                  `The Biden logo has ties to the American flag, which makes it dynamic and accessible. Its simplicity also allows for __easy readability and clear visibility on a variety of backgrounds.__`,
                  `The Trump logo evokes feelings of power, success, light, and achievement. However, the __varying elements and font sizes could potentially make readability a challenge__ with different backgrounds and situations.`
                ]
              },
              {
                comparison: true,
                title: 'Clunky navigation for Biden, and site readability issues for Trump',
                images: ["biden-design-4.png", "trump-design-4.png"],
                alt: ["biden-design-4", "trump-design-4"],
                copy: [
                  `On the Biden site, the navigation __feels sporadic and oddly uneven.__ The “donate” option hugs uncomfortably close to the menu button (which feels claustrophobic on its own: three lines plus a nearly overlapping “menu” title).`,
                  `The Trump site’s white text navigation seamlessly blends into each page’s content, allowing the eye to follow a nice flow. The only challenge with white text is __finding dark enough photos that don’t challenge readability.__`
                ]
              },
              {
                comparison: false,
                title: 'Coalitions address Trump’s audience',
                images: ["design-5.png"],
                alt: ["design-5"],
                copy: [
                  `The Trump campaign’s logo design (outline box topped with five stars) carries throughout his coalitions as well. With each coalition, the color and typography change to reflect that group’s target audience. This is a good visual connection to the campaign’s sub branches. The Biden campaign misses an opportunity to connect with sub groups by not having any carrying visual identities for its coalitions.`
                ]
              },
              {
                comparison: true,
                title: 'Biden’s pop-ups invite human connection, Trump’s urgency',
                images: ["biden-design-6.png", "trump-design-6.png"],
                alt: ["biden-design-6", "trump-design-6"],
                copy: [
                  `The Biden campaign’s popup upon entering the site __shows Biden and Harris as a casual, friendly team.__ This shows a desire to connect with viewers on a more human level.`,
                  `Trump: The Trump campaign’s popup is flashy, eye-catching, and in-your-face. It’s design is reminiscent of the 1877 “I Want You” poster, __purposefully loud and urgent.__`
                ]
              },
              {
                comparison: true,
                title: 'Both are guilty of CTA overload',
                images: ["biden-design-7.png", "trump-design-7.png"],
                alt: ["biden-design-7", "trump-design-7"],
                copy: [
                  `The Biden site’s second fold includes a donation section where users see many dollar amount options, allowing them to make quick dollar amount decisions as well as eliminating the pressure of how much is expected. We also get a sense of peer support with the constant scrolling pop up of “so-and-so from [state] donated X.” However, the __blue form fill feels heavy and almost uninviting.__`,
                  `Trump: The second fold on the Trump site’s homepage feels a bit squatty and crowded. So many __calls to action, text, colors, and busy photo backgrounds__ are a lot for users to digest. This space also lacks a clear hierarchy to guide the user down the page, and some of the white text is difficult to read over the photo backgrounds. Darker or more simple photos would help increase readability.`
                ]
              },
              {
                comparison: true,
                title: 'Trump pushes design limits too far, while Biden plays it safe',
                images: ["biden-design-8.png", "trump-design-8.png"],
                alt: ["biden-design-8", "trump-design-8"],
                copy: [
                  `Next, the Biden site uses white boxes with simple, easy-to-read statements that lack visual interest. Matching the influence of the copy with an interesting design would improve this section. The volunteer section’s illustrations are approachable and celebrate diversity. __These illustrations were a great way to show the campaign’s values without saying a word.__`,
                  `The Trump site again uses white text, which is difficult to read over busy photo backgrounds. Next comes a __rebranded Twitter feed, barely recognizable as such because of a lack of familiarity.__ Branding this feed toward the Trump campaign instead of allowing it to be recognizable as a Twitter feed makes it difficult for users to know how to interact with it.`
                ]
              },
              {
                comparison: true,
                title: 'Full marks for using the footer well',
                images: ["biden-design-9.png", "trump-design-9.png"],
                alt: ["biden-design-9", "trump-design-9"],
                copy: [
                  `The Biden site has a thick footer that gives a lot of information in a very structured way. __The column layout is clear and easy to understand,__ giving the user an easy way to compartmentalize the grouped content.`,
                  `The Trump site’s footer is straightforward and well organized. It’s easy for users to navigate because similar content is grouped together, __making for a well-ordered hierarchy.__`
                ]
              },
              {
                poll: true,
                question: "In your opinion, who has better design?",
                pollKey: 'design'
              }
            ]}
          />
        </div>

        <div className="section">
          <TopicSection 
            title="MOBILE APP UX"
            slides={[
              {
                comparison: true,
                title: 'Trump’s app offers more incentives and personalization than Biden’s',
                images: ["biden-mobile-1.png", "trump-mobile-1.png"],
                alt: ["biden-mobile-1", "trump-mobile-1"],
                copy: [
                  `Both the Biden 2020 and the Trump 2020 apps are centered on encouraging users to donate, volunteer, attend events, and share the app. The Biden app simply __focuses on enabling users to take these actions__ easily within the app.`,
                  `A different approach allows the Trump app to achieve the same goal. Rather than enabling users to act, the focus of the Trump app is on __providing the user with benefits and incentives__ in order to take the desired action.`
                ]
              },
              {
                comparison: true,
                title: 'Biden uses the app to share vision',
                images: ["biden-mobile-2.png", "trump-mobile-2.png"],
                alt: ["biden-mobile-2", "trump-mobile-2"],
                copy: [
                  `The Sign-up/Sign-in page on the Biden app directly quotes the Biden campaign’s vision. It also conveys a __sense of intimacy__ with Joe Biden by labeling the quote “Joe’s Vision,” rather than “Joe Biden’s Vision” or “Biden’s Vision.”`,
                  `The Trump app has more __subtle messaging than the Biden__ app. On the Sign-up/Sign-in page, the image of President Trump and his campaign’s slogan “Keep America Great” carry most of the message.`
                ]
              },
              {
                comparison: true,
                title: 'Trump holds the user’s hand after sign-in',
                images: ["biden-mobile-3.png", "trump-mobile-3.png"],
                alt: ["biden-mobile-3", "trump-mobile-3"],
                copy: [
                  `Language alluding to war is present in both apps’ sign-in process. The Biden app uses the messaging: “We are in a Battle for the soul of America,” before directing users to the homepage. The Biden app __messaging appeals to a more altruistic audience,__ with no benefits clearly stated for the users themselves.`,
                  `Before reaching the homepage of the Trump app, users must swipe through a series of entry slides. One slide includes the message: “As a reward for helping us fight against socialist Democrats you can earn items like Expedited Entry and special event access.” This clear messaging allows __users to understand the benefits of using the app upfront.__`
                ]
              },
              {
                comparison: false,
                title: 'Biden’s donation tab needs attention',
                images: ["mobile-4.png"],
                alt: ["mobile-4"],
                blueBold: true,
                copy: [
                  `The donate tab on the Biden app is unlabeled, making it difficult to find. Once users enter the tab, they see only the Biden/Harris logo and a brief message. __The donation button is not visible without scrolling down.__ Shrinking the white space and instead displaying the donation button would be advisable. Additionally, the button opens a new tab on a web browser. Leaving the app in order to perform a function such as donating is not user-friendly.`
                ]
              },
              {
                comparison: true,
                title: 'The Trump app offers a personalized profile experience',
                images: ["biden-mobile-5.png", "trump-mobile-5.png"],
                alt: ["biden-mobile-5", "trump-mobile-5"],
                copy: [
                  `The Profile Page within the Biden app contains only basic user information. __This does not take advantage of the potential for interaction between the user and the app.__`,
                  `The Trump app’s Profile Page prompts a question: “How can I Earn Reward Points?” The question is then answered with a link. __The interactive nature of the page continues with points, donations, and links to upcoming events.__`
                ]
              },
              {
                comparison: false,
                title: 'Trump’s SMS messaging will likely go to spam',
                images: ["mobile-6.png"],
                alt: ["mobile-6"],
                copy: [
                  `Both Biden and Trump send SMS messages asking for donations, or volunteer opportunities. But the Trump campaign sends __texts so often that within a week, most phones will likely tag them as spam__ messages unless they are engaged with.`
                ]
              },
              {
                poll: true,
                question: "In your opinion, who has better mobile app UX?",
                pollKey: 'mobile-app-ux'
              }
            ]}
          />
        </div>

        <div className="section">
          <TopicSection 
            title="TV COMMERCIALS"
            slides={[
              {
                comparison: true,
                title: 'Biden captures state markets, while Trump pushes national',
                images: ["biden-tv-1.png", "trump-tv-1.png"],
                alt: ["biden-tv-1", "trump-tv-1"],
                copy: [
                  `When looking at the Biden campaign’s budget for television commercials it’s easy to see that __state markets are priority over the national market.__ The Biden team has allocated $77,000 to national ads and a whopping $16,044,000 cumulatively to individual state markets.`,
                  `The Trump campaign seems to have an opposite approach, __prioritizing the national television commercials over state marketing__ with $14,400,000 allocated nationally and $3,094,000 spent on state markets.`
                ]
              },
              {
                comparison: true,
                title: 'Trump is ignoring Florida',
                images: ["biden-tv-2.png", "trump-tv-2.png"],
                alt: ["biden-tv-2", "trump-tv-2"],
                copy: [
                  `__Florida is clearly the priority for the Biden campaign’s televised commercial push,__ with $4,460,000 spent so far (27% of their total commercial budget). Additionally, the Biden campaign has created five different ads specifically for Florida.`,
                  `If the Trump campaign continues down this path, Floridians shouldn’t expect to see any Florida-specific ads featuring the POTUS. However, the Trump campaign has state-specific ads for Colorado, Arizona, and other swing states. This may be a strategic, but given the fact that __both Google and Facebook Ads budgets cap out in Florida, this feels more like an oversight.__`
                ]
              },
              {
                comparison: false,
                title: 'Biden has created commercials in Spanish',
                images: ["tv-3.png"],
                alt: ["tv-3"],
                copy: [
                  `The Biden campaign is giving the Spanish-speaking audience the attention they merit. Team Biden has produced a commercial in Florida that is entirely in Spanish, not just translated from English, but specifically produced for this audience.`
                ]
              },
              {
                comparison: false,
                title: 'The Iowa market has more commercials than the national market',
                images: ["tv-4.png"],
                alt: ["tv-4"],
                copy: [
                  `Both candidates are doubling down on specific messaging for Iowa, a notorious swing state, but this is especially apparent from the Biden campaign. Between the two of them, they have created 26 commercials for Iowa alone (4 from Trump and 22 from Biden). While the national market has only 20 individual commercials (13 from team Trump and 7 from team Biden).`
                ]
              },
              {
                comparison: false,
                title: 'Biden and Trump have near identical television budgets',
                images: ["tv-5.png"],
                alt: ["tv-5"],
                copy: [
                  `On every other platform the Trump campaign outspends the Biden campaign by a landslide, but when it comes to television budgets, they are nearly even. Team Trump still spends more, but only slightly with $17,700,000 spent so far and the Biden campaign, $16,100,000.`
                ]
              },
              {
                poll: true,
                question: "In your opinion, who has a better TV commercial campaign?",
                pollKey: 'tv-commercials'
              }
            ]}
          />
        </div>

        {/* <div className="section">
          <Kanye />
        </div> */}

        <div className="section">
          <TopicSection 
            title="KANYE"
            slides={[
              {
                kanye: true
              },
              {
                comparison: false,
                title: 'Kanye is upfront about what he stands for',
                images: ["kanye-1.png"],
                alt: ["kanye-1"],
                copy: [
                  `Unlike other candidates, Kanye West is front and center with his vision and values. Nearly all of the real estate on his website is devoted to 10 campaign values that fall under his platform, “Creating a Culture of Life.” Each one is reinforced with a verse from the Bible.`
                ]
              },
              {
                comparison: false,
                title: 'Is that a sign-up form?',
                images: ["kanye-2.png"],
                alt: ["kanye-2"],
                copy: [
                  `Many users are mistaking the email sign-up form for a headline due to it’s position and font on the homepage. This is surely losing thousands of email addresses due to unique design.`
                ]
              },
              {
                comparison: false,
                title: 'SEO is a major issue for Kanye',
                images: ["kanye-3.png"],
                alt: ["kanye-3"],
                copy: [
                  `Aside from being nearly impossible to find (try searching “Kanye West for president” and see if you can find anything), Kanye’s campaign is virtually dead in every SEO metric. The website only has a handful of pages, and just 702 backlinks — a paltry number when you consider the amount of media attention his candidacy has received thus far.`
                ]
              },
              {
                comparison: false,
                title: 'Kanye isn’t advertising',
                images: ["kanye-4.png"],
                alt: ["kanye-4"],
                copy: [
                  `While Biden and Trump are spending millions of dollars on advertising month after month, Kanye West’s team hasn’t pulled the trigger on any ad placements to speak of.`
                ]
              },
              {
                comparison: false,
                title: 'Yeezy has other priorities on social media',
                images: ["kanye-5.png"],
                alt: ["kanye-5"],
                copy: [
                  `Trump and Biden have vastly different personal Twitter accounts, but there's no doubt that both are dedicated to their political career. Kanye West's Twitter account is rarely political, usually dominated instead by tweets about the arts, music industry, and business.`
                ]
              },
              {
                comparison: false,
                title: 'Don’t expect any emails from Kanye',
                images: ["kanye-6.png"],
                alt: ["kanye-6"],
                copy: [
                  `In keeping up with his minimalist branding, fans who provide an email address on the rapper’s campaign site shouldn’t expect to be receiving emails anytime soon. It appears that the West 2020 team doesn’t have a welcome email set up, or any follow up email funnel to speak of.`
                ]
              },
              {
                comparison: false,
                title: 'Kanye isn’t here to pick a fight',
                images: ["kanye-7.png"],
                alt: ["kanye-7"],
                copy: [
                  `While the other two candidates spend a lot of time and space dismissing or calling out their counterpart on their website, Kanye doesn’t mention Trump or Biden on his website once.`
                ]
              },
              
            ]}
          />
        </div>

      </div>
    </Background>
  )
}

export default View