import React from 'react'

import Flex from './Flex'
import Image from './Image'
import Text from './Text'
import Markdown from './Markdown'

const Slide = ({ sectionTitle, comparison, copy, images, title, step, total, mobileNum, highres, blueBold }) => {
  if (comparison) return (
    <>
      {(mobileNum || mobileNum === 0) ? (
        <Flex column padding="8px">
          <Flex column margin="2rem auto 0" align="center" height="">
            <Text subtitle >{sectionTitle}</Text>
            <Text subtitle>{step} of {total}</Text>
            <Text title margin="0 auto" padding="0 10px" mobilePlus={`margin: 8px auto 0;`} width="640px" center>
              {title}
            </Text>
          </Flex>

          <Flex column margin="32px 0"  tabletPlus={`padding-left:50px`}  mobilePlus={`margin: 16px auto; padding-left: 0 !important`} width="450px">
            {images[mobileNum] && <Image maxHeight="35vh" margin="0 auto 32px" mobilePlus={`margin: 0 auto 8px;`} alt={`97f-pres-marketing-${images[mobileNum].replace('.png', '')}`} src={`/${(highres ? `${images[mobileNum].split('.')[0]}@2x.${images[mobileNum].split('.')[1]}` : images[mobileNum])}`} />}

            {copy[mobileNum] && <Markdown blue={mobileNum === 0} source={copy[mobileNum]} />}
          </Flex>
        </Flex>
      ) : (
        <Flex column padding="8px">
          <Flex column margin="2rem auto 0" align="center">
            <Text subtitle >{sectionTitle}</Text>
            <Text subtitle>{step} of {total}</Text>
            <Text title margin="0 auto 20px" padding="0 10px" width="640px" center>
              {title}
            </Text>
          </Flex>

          <Flex justify="space-between" width="1050px" margin="0 auto" tabletPlus={`padding-left:50px`} mobilePlus={`margin: 16px auto; padding-left: 0 !important`}>
            <Flex column margin="32px 32px 32px 0" width="450px">
              {images[0] && <Image maxHeight="35vh" margin="0 auto 32px" mobilePlus={`margin: 0 auto 8px;`} alt={`97f-pres-marketing-${images[0].replace('.png', '')}`} src={`/${(highres ? `${images[0].split('.')[0]}@2x.${images[0].split('.')[1]}` : images[0])}`} />}

              {copy[0] && <Markdown blue source={copy[0]} />}
            </Flex>

            <Flex column margin="32px 0 32px" width="450px">
              {images[1] && <Image maxHeight="35vh" margin="0 auto 32px" mobilePlus={`margin: 0 auto 8px;`} alt={`97f-pres-marketing-${images[1].replace('.png', '')}`} src={`/${(highres ? `${images[1].split('.')[0]}@2x.${images[1].split('.')[1]}` : images[1])}`} />}

              {copy[1] && <Markdown source={copy[1]} />}
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  )
  return (
    <Flex column padding="8px">
      <Flex column margin="2rem auto 0" align="center">
        <Text subtitle >{sectionTitle}</Text>
        <Text subtitle>{step} of {total}</Text>
        <Text title margin="0 auto 20px" padding="0 10px" mobilePlus={`margin: 8px auto 0;`} width="700px" center>
          {title}
        </Text>
      </Flex>

      <Flex column width="1050px" margin="0 auto" tabletPlus={`padding-left:50px`} mobilePlus={`margin: 16px auto; padding-left: 0 !important`} >
        {images.length > 1 ? (
          <Flex justify="space-between" margin="0 auto">
            {images[0] && <Image maxHeight="50vh" margin="0 32px 32px" mobilePlus={`margin: 0 8px 8px;`} alt={`97f-pres-marketing-${images[0].replace('.png', '')}`} src={`/${(highres ? `${images[0].split('.')[0]}@2x.${images[0].split('.')[1]}` : images[0])}`} />}

            {images[1] && <Image maxHeight="50vh" margin="0 0 32px" mobilePlus={`margin: 0 0 8px;`} alt={`97f-pres-marketing-${images[1].replace('.png', '')}`} src={`/${(highres ? `${images[1].split('.')[0]}@2x.${images[1].split('.')[1]}` : images[1])}`} />}
          </Flex>
        ) : (
          <Image maxHeight="50vh" margin="0" alt={`97f-pres-marketing-${images[0].replace('.png', '')}`} src={`/${(highres ? `${images[0].split('.')[0]}@2x.${images[0].split('.')[1]}` : images[0])}`} />
        )}

        {copy[0] && <Markdown blue={blueBold} source={copy[0]} />}
      </Flex>
    </Flex>
  )
}

export default Slide